import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import {
  sectorCategoories,
  servicesCategories,
  decodeEntities,
} from "../../../utils/helpers";
//import '../PostListNavigation.scss'

export const ProjectListNavigation = ({ categories }) => {
  const [location, setLocation] = useState("");
  const [serviceFilters, setServiceFilters] = useState([]);
  const [industryFilters, setIndustryFilters] = useState([]);

  useEffect(() => {
    setLocation(window?.location?.pathname?.split("/").pop());

    const alphabetise = (array) => {
      return array.sort((a, b) => a.node.name.localeCompare(b.node.name));
    };
    const filteredIndustries = getNavigationFilters(categories, 34);
    const filteredServices = getNavigationFilters(categories, 33);

    setIndustryFilters(alphabetise(filteredIndustries));
    setServiceFilters(alphabetise(filteredServices));
  }, [categories]);

  const handleChange = (path) => {
    if (!path) return null;
    if (path === "/projects/") {
      navigate(`/projects/`);
    } else {
      navigate(`/project-category/${path}`);
    }
  };

  // returns an array of categories from the parent category. The number passed to this is the ID of the category in WP
  const getNavigationFilters = (categories, parentCategory) => {
    let finalCategories = [];
    for (let i = 0; i < categories.length; i++) {
      const element = categories[i];
      if (element.node.wordpress_parent === parentCategory) {
        finalCategories.push(element);
      }
    }
    return finalCategories;
  };

  return (
    <>
      <section className="post-list-navigation">
        <div className="filter-container">
          <label className="filter-label" htmlFor="filter-service">
            Sector:
          </label>
          <select
            className="filter-select"
            name="filter-industry"
            id="filter-industry"
            value={location}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="/projects/">All</option>
            {industryFilters &&
              industryFilters.map(
                ({ node: category }, index) =>
                  category.slug !== "uncategorised" && (
                    <option key={index} value={category.slug}>
                      {decodeEntities(category.name)}
                    </option>
                  )
              )}
          </select>
        </div>

        <div className="filter-container">
          <label className="filter-label" htmlFor="filter-industry">
            Service:
          </label>
          <select
            className="filter-select"
            name="filter-service"
            id="filter-service"
            value={location}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="/projects/">All</option>
            {serviceFilters &&
              serviceFilters.map(
                ({ node: category }, index) =>
                  category.slug !== "uncategorised" && (
                    <option key={index} value={category.slug}>
                      {decodeEntities(category.name)}
                    </option>
                  )
              )}
          </select>
        </div>
      </section>
    </>
  );
};
