import React from "react";
import "./RelatedPass.scss";
import { Carousel } from "../../Carousel";
import { ImagePass } from "../../Image/ImagePass";
import { PostListItem } from "../../Blog/PostListItem";
import GatsbyLink from "../../GatsbyLink";

export const RelatedPass = ({
  title,
  data: { allWordpressWpProjects, allWordpressPost, categories },
  date_format,
  featuredItems,
  shouldDisplayCTA,
}) => {
  // if no blog posts have been selected then return nothing
  if (!featuredItems) {
    return null;
  }

  const postPass = allWordpressWpProjects
    ? allWordpressWpProjects
    : allWordpressPost;

  // this logic sets whether the date should be shown or not
  // post = dates, projects = category
  const dateBeShown = allWordpressPost ? true : false;

  const { edges: posts } = postPass;

  // creates an array of the selected posts in WP
  const filterItemsArray = featuredItems.map(function (obj) {
    return obj.wordpress_id;
  });

  // filters through all projects and returns the ones which match the filterItemsArray
  const filteredResults = posts.filter((post) =>
    filterItemsArray.includes(post.node.wordpress_id)
  );

  const swiperSettings = {
    id: "related-carousel",
    slidesPerView: 1,
    spaceBetween: 24,
    grabCursor: false,
    pagination: false,
    navigation: true,
    speed: 2000,
    autoplay: {
      delay: 5000,
    },
  };

  return (
    <section className="post-feed">
      <div className="wrap">
        {title && <h2 className="heading">{title}</h2>}
        <Carousel swiperSettings={swiperSettings}>
          {Object.keys(filteredResults).length > 0 &&
            filteredResults?.map((post, index) => {
              return (
                <div className="slide" key={index}>
                  <div className="image-container">
                    <ImagePass src={post.node.featured_media} />
                  </div>
                  <div className="content">
                    <PostListItem
                      key={index}
                      data={post.node}
                      categories={categories}
                    />
                  </div>
                </div>
              );
            })}
        </Carousel>
        {shouldDisplayCTA && (
          <div className="project-feed-cta">
            <GatsbyLink to="/projects" className="button--mid">
              VIEW ALL PROJECTS
            </GatsbyLink>
          </div>
        )}
      </div>
    </section>
  );
};
